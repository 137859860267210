import React from "react"
import { navigate } from 'gatsby-link'
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactPage = () => {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => console.log(error))
  }

  return (
    <Layout>
      <SiteMetadata title="Contact" description="Contact Tara Segda" />
      <div className="bg-gray-100 py-12 lg:py-16 min-h-1/2">
        <section className="text-gray-700 body-font">
          <h1 className="text-xl text-center font-bold tracking-tight text-gray-900 sm:text-4xl">
            Send Me A Message
          </h1>
          <div className="container px-8 pt-12 pb-24 mx-auto lg:px-4">
            <form
              name="contact"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              method="POST"
              action="/thanks/"
              onSubmit={handleSubmit}
              className="bg-white flex flex-col w-full p-8 mx-auto mt-10 border rounded-lg lg:w-1/2 md:w-2/3 md:ml-auto md:mt-0"
            >
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={handleChange} />
                </label>
              </p>
              <div className="relative ">
                <input
                  type="name"
                  id="name"
                  name="name"
                  placeholder="name"
                  onChange={handleChange}
                  className="w-full px-4 py-2 mb-4 mr-4 text-base text-gray-900 bg-gray-100 border-transparent rounded-lg focus:border-gray-500 focus:bg-white focus:ring-0"
                ></input>
              </div>
              <div className="relative ">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="email address"
                  onChange={handleChange}
                  className="w-full px-4 py-2 mb-4 mr-4 text-base text-gray-900 bg-gray-100 border-transparent rounded-lg focus:border-gray-500 focus:bg-white focus:ring-0"
                ></input>
              </div>
              <div class="relative ">
                <textarea
                  type="message"
                  id="message"
                  name="message"
                  placeholder="message"
                  onChange={handleChange}
                  className="w-full px-4 py-2 mb-4 mr-4 text-base text-gray-900 bg-gray-100 border-transparent rounded-lg focus:border-gray-500 focus:bg-white focus:ring-0"
                ></textarea>
              </div>
              <button
                type="submit"
                className="px-8 py-2 font-semibold text-white transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-purple-800 focus:ring focus:outline-none"
              >
                Submit
              </button>
            </form>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ContactPage
